import {Injectable, Injector} from '@angular/core';
import {filter, Observable, of, tap} from 'rxjs';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {REQUESTS_HASH} from 'src/app/helpers/core/misc.helper';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {RadPatientDto} from 'src/app/interfaces/backend/dto/rad-patient-dto.interface';
import {RadiologyPatientFilter} from 'src/app/interfaces/backend/other/radiology-patient-filter.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {displayError, tapDisplaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class PatientsFacade {
    public dataPage: Page<RadPatientDto> | null = null;
    public activeItem: RadPatientDto | null = null;

    constructor(
        private radiologyControllerService: RadiologyControllerService,
        private injector: Injector
    ) {
    }

    public reset() {
        this.dataPage = null;
        this.activeItem = null;
    }

    public loadData(pageable: Pageable = {}, radiologyPatientFilter: RadiologyPatientFilter = {}) {
        this.loadDataLogic(pageable, radiologyPatientFilter).subscribe();
    }

    public loadDataLogic(pageable: Pageable = {}, radiologyPatientFilter: RadiologyPatientFilter = {}) {
        return this.getData(pageable, radiologyPatientFilter).pipe(
            tap(data => this.dataPage = data)
        );
    }

    public getData(pageable: Pageable = {}, radiologyPatientFilter: RadiologyPatientFilter = {}) {
        return this.radiologyControllerService.getPatients(pageable, radiologyPatientFilter);
    }

    public loadItem(userId: number) {
        this.getItem(userId).pipe(
            tap(data => this.activeItem = data)
        ).subscribe();
    }

    public getItem(userId: number) {
        return this.radiologyControllerService.getPatient(userId);
    }

    public create(radPatientDto: RadPatientDto, callback?: () => void) {
        this.radiologyControllerService.createPatient(radPatientDto).pipe(
            tap((data) => {
                this.activeItem = data;
                callback?.();
            }),
            tapDisplaySuccess(this.injector)
        ).subscribe();
    }

    public update(radPatientDto: RadPatientDto, callback: () => void) {
        const fn = async (id: number) => {
            return this.radiologyControllerService.updatePatient(id, radPatientDto).pipe(
                tap(data => {
                    this.activeItem = data;
                    callback();
                }),
                tapDisplaySuccess(this.injector)
            ).subscribe();
        };

        this.getItemId(fn);
    }

    public getItemId<T>(fn: (id: number) => T) {
        const id = this.activeItem?.patientId;
        if (!id) {
            displayError(this.injector);
            return;
        }

        return fn(id);
    }

    public handleSubject(param?: string, requestsHash?: string) {
        if (!param) return of(null);

        const userId = Number.parseInt(param);
        const request: Observable<RadPatientDto | null> = param === SiteRoute.CREATE ? of(null) : this.getItem(userId);
        return request.pipe(
            filter(() => requestsHash === REQUESTS_HASH),
            tap(data => this.activeItem = data)
        );
    }
}